@import url('./assets/scss/theme.scss');

img {
    width: 100%;
}

.buns-icon {
    width: 52px;
}

.main-container {
    background: linear-gradient(180deg, #F9F5E2 0%, #FCEDE5 100%);
}

.blue-container {
    background: #F4F8FC;
}

.white-card {
    background-color: #ffffff;
}

.accordion-button {
    width: 20px;
}

.solutions-wrap {
    background-image: url('./assets/img/bg-1.png');
    background-size: 100% 100%;

    img {
        height: 100%;
    }

    p,
    li {
        color: #000000;
    }
}

.about-wrap {
    background-image: url('./assets/img/bg-2.png');
    background-size: 100% 100%;

    .history-title {
        position: relative;
    }

    .text-separate {
        width: 100%;
        height: 1px;
        background: white;
        position: absolute;
        left: 12px;
        top: 24px;

    }

}

.vacancies-wrap {
    background-image: url('./assets/img/bg-3.png');
    background-size: 100% 100%;
}

.card-lg {
    color: #ffffff;
    line-height: 132px;
    width: 415px;
    height: 132px;
    font-size: 24px;
}

.flex-col-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.email-link {
    color: #F57C00;
    text-decoration: underline;

    &--no-underline {
        text-decoration: none;
    }
}

.text-bold {
    font-weight: 600;
}



.separate {
    // height: 24px;
    width: 1px;
    background: #bebebe;
}

.buns-card {
    display: flex;
    flex-direction: column;
    min-height: 300px;
    justify-content: space-around;
}

.modal-header {
    border-bottom: none;
}

.phone-input-container.react-tel-input .form-control {
    width: 100%;
    border: var(--ar-border-width) solid var(--ar-gray-400);
    border-radius: 16px;
    height: 48px;
}

.footer {
    font-size: 14px;
}

.bg-card {
 background-color: #fdeddf;
 }